/*eslint max-lines-per-function: off*/
/*eslint max-lines: off*/
import React from "react";
import styles from "./dsgvo.module.scss";

export const DSGVO = () => {
  return (
    <div className={styles.wrapper}>
      <h1>Datenschutzgrundverordnung</h1>
      <p>
        Allgemeine Hinweise und Pflichtinformationen zum Datenschutz
        <br />
        <br />
        <b>Datenschutz</b>
        <br />
        <br />
        Wir nehmen den Schutz Ihrer persönlichen Daten sehr ernst. Wir behandeln
        Ihre personenbezogenen Daten vertraulich und entsprechend der
        gesetzlichen Datenschutzvorschriften sowie dieser Datenschutzerklärung.
        <br />
        <br />
        Wenn Sie diese Website benutzen, werden verschiedene personenbezogene
        Daten erhoben. Personenbezogene Daten sind Daten, mit denen Sie
        persönlich identifiziert werden können. Die vorliegende
        Datenschutzerklärung erläutert, welche Daten wir erheben und wofür wir
        sie nutzen. Sie erläutert auch, wie und zu welchem Zweck das geschieht.
        <br />
        <br />
        Wir weisen darauf hin, dass die Datenübertragung im Internet (z.B. bei
        der Kommunikation per E-Mail) Sicherheitslücken aufweisen kann. Ein
        lückenloser Schutz der Daten vor dem Zugriff durch Dritte ist nicht
        möglich.
        <br />
        <br />
        <b>Hinweis zur verantwortlichen Stelle</b>
        <br />
        <br />
        Die verantwortliche Stelle für die Datenverarbeitung auf dieser Website
        ist:
        <br />
        <br />
        Ohana Circle (Verein in Gründung)
        <br />
        Dr. Mandy Schönherr, Kristina Gottschling
        <br />
        <br />
        c/o Dr. Mandy Schönherr
        <br />
        Bertinistraße 11
        <br />
        14469 Potsdam
        <br />
        <br />
        Telefon: +49 160 93915919
        <br />
        E-Mail: info@ohanacircle.de:
        <br />
        <br />
        <b>Widerruf Ihrer Einwilligung zur Datenverarbeitung</b>
        <br />
        <br />
        Viele Datenverarbeitungsvorgänge sind nur mit Ihrer ausdrücklichen
        Einwilligung möglich. Sie können eine bereits erteilte Einwilligung
        jederzeit widerrufen. Dazu reicht eine formlose Mitteilung per E-Mail an
        uns. Die Rechtmäßigkeit der bis zum Widerruf erfolgten Datenverarbeitung
        bleibt vom Widerruf unberührt. <br />
        <br />
        <b>Recht auf Datenübertragbarkeit</b>
        <br />
        <br /> Sie haben das Recht, Daten, die wir auf Grundlage Ihrer
        Einwilligung oder in Erfüllung eines Vertrags automatisiert verarbeiten,
        an sich oder an einen Dritten in einem gängigen, maschinenlesbaren
        Format aushändigen zu lassen. Sofern Sie die direkte Übertragung der
        Daten an einen anderen Verantwortlichen verlangen, erfolgt dies nur,
        soweit es technisch machbar ist.
        <br />
        <br />
        <b>Auskunft, Sperrung, Löschung</b>
        <br />
        <br /> Sie haben im Rahmen der geltenden gesetzlichen Bestimmungen
        jederzeit das Recht auf unentgeltliche Auskunft über Ihre gespeicherten
        personenbezogenen Daten, deren Herkunft und Empfänger und den Zweck der
        Datenverarbeitung und ein Recht auf Berichtigung, Sperrung oder Löschung
        dieser Daten. Hierzu sowie zu weiteren Fragen zum Thema personenbezogene
        Daten können Sie sich jederzeit unter der im Impressum angegebenen
        Adresse an uns wenden. <br />
        <br />
        <b>Widerspruch gegen Werbe-Mails</b>
        <br />
        <br /> Der Nutzung von im Rahmen der Impressumspflicht veröffentlichten
        Kontaktdaten zur Übersendung von nicht ausdrücklich angeforderter
        Werbung und Informationsmaterialien wird hiermit widersprochen. Die
        Betreiber der Seiten behalten sich ausdrücklich rechtliche Schritte im
        Falle der unverlangten Zusendung von Werbeinformationen, etwa durch
        Spam-E-Mails, vor. <br />
        <br />
        <b>Datenerfassung auf unserer Website</b>
        <br />
        <br /> 1. Verwendung von Cookies Unsere Webseite verwendet Cookies. Bei
        Cookies handelt es sich um Textdateien, die im Internetbrowser bzw. vom
        Internetbrowser auf dem Computersystem des Nutzers gespeichert werden.
        Ruft ein Nutzer eine Website auf, so kann ein Cookie auf dem
        Betriebssystem des Nutzers gespeichert werden. Dieser Cookie enthält
        eine charakteristische Zeichenfolge, die eine eindeutige Identifizierung
        des Browsers beim erneuten Aufrufen der Website ermöglicht. Die
        Rechtsgrundlage für die Verarbeitung personenbezogener Daten unter
        Verwendung von Cookies ist Art. 6 Abs. 1 lit. f DSGVO. Cookies werden
        auf dem Rechner des Nutzers gespeichert und von diesem an unserer Seite
        übermittelt. Daher haben Sie als Nutzer auch die volle Kontrolle über
        die Verwendung von Cookies. Durch eine Änderung der Einstellungen in
        Ihrem Internetbrowser können Sie die Übertragung von Cookies
        deaktivieren oder einschränken. Bereits gespeicherte Cookies können
        jederzeit gelöscht werden. Dies kann auch automatisiert erfolgen. Werden
        Cookies für unsere Website deaktiviert, können möglicherweise nicht mehr
        alle Funktionen der Website vollumfänglich genutzt werden. <br />
        <br />
        2. Server-Log-Dateien und Speicherung der Daten
        <br />
        <br /> Bei jedem Aufruf unserer Internetseite erfasst unser System
        automatisiert Daten und Informationen vom Computersystem des aufrufenden
        Rechners. Folgende Daten werden hierbei erhoben: IP-Adresse verwendetes
        Betriebssystem User-Agents Datum und Uhrzeit der Serveranfrage Die
        Speicherung in Logfiles erfolgt, um die Funktionsfähigkeit der Website
        sicherzustellen. Zudem dienen uns die Daten zur Optimierung der Website
        und zur Sicherstellung der Sicherheit unserer informationstechnischen
        Systeme. Eine Auswertung der Daten zu Marketingzwecken findet in diesem
        Zusammenhang nicht statt. In diesen Zwecken liegt auch unser
        berechtigtes Interesse an der Datenverarbeitung nach Art. 6 Abs. 1 lit.
        f DSGVO. Die Daten werden gelöscht, sobald sie für die Erreichung des
        Zweckes ihrer Erhebung nicht mehr erforderlich sind. <br />
        <br />
        <b>Newsletter</b>
        <br />
        <br /> Der Newsletter-Versand erfolgt aufgrund der Anmeldung des Nutzers
        auf der Website ohanacircle.de
        <br />
        <br />
        Auf unserer Internetseite besteht die Möglichkeit einen kostenfreien
        Newsletter zu abonnieren. Dabei werden bei der Anmeldung zum Newsletter
        die Daten aus der Eingabemaske an uns übermittelt. Konkret speichern wir
        die E-Mail Adresse zur Übermittlung unseres Newsletters.
        <br />
        <br />
        Für die Verarbeitung der Daten wird im Rahmen des Anmeldevorgangs Ihre
        Einwilligung eingeholt.
        <br />
        <br />
        Es erfolgt im Zusammenhang mit der Datenverarbeitung für den Versand von
        Newslettern keine Weitergabe der Daten an Dritte. Die Daten werden
        ausschließlich für den Versand des Newsletters verwendet.
        <br />
        <br />
        Das Abonnement des Newsletters kann durch den betroffenen Nutzer
        jederzeit gekündigt werden. Zu diesem Zweck findet sich in jedem
        Newsletter ein entsprechender Link.
        <br />
        <br />
        <b>Soziale Medien</b>
        <br />
        <br />
        Inhalte teilen über Plugins (Instagram)
        <br />
        <br />
        Die Inhalte auf unseren Seiten können datenschutzkonform in sozialen
        Netzwerken wie Instagram, Twitter oder Google+ geteilt werden. Eine
        automatische Übertragung von Nutzerdaten an die Betreiber dieser
        Plattformen erfolgt nicht.
        <br />
        <br />
        Auf unseren Seiten sind ggf. Funktionen des Dienstes Instagram
        eingebunden. Diese Funktionen werden integriert durch die Instagram
        Inc., 1601 Willow Road, Menlo Park, CA 94025, USA.
        <br />
        <br />
        Wenn Sie in Ihrem Instagram-Account eingeloggt sind, können Sie durch
        Anklicken des Instagram-Buttons die Inhalte unserer Seiten mit Ihrem
        Instagram-Profil verlinken. Dadurch kann Instagram den Besuch unserer
        Seiten Ihrem Benutzerkonto zuordnen. Wir weisen darauf hin, dass wir als
        Anbieter der Seiten keine Kenntnis vom Inhalt der übermittelten Daten
        sowie deren Nutzung durch Instagram erhalten.
        <br />
        <br />
        Weitere Informationen hierzu finden Sie in der Datenschutzerklärung von
        Instagram: https://help.instagram.com/519522125107875
        <br />
        <br />
        <b>Rechtsgrundlage</b>
        <br />
        <br />
        Die Datenverarbeitung erfolgt auf Grundlage Ihrer Einwilligung (Art. 6
        Abs. 1 lit. a DSGVO). Sie können diese Einwilligung jederzeit
        widerrufen. Die Rechtmäßigkeit der bereits erfolgten
        Datenverarbeitungsvorgänge bleibt vom Widerruf unberührt.
      </p>
    </div>
  );
};
