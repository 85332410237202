import React from "react";
import instagram from "../../../assets/img/instagramIcon.png";
import linkedIn from "../../../assets/img/linkedInIcon.png";
import logoDark from "../../../assets/img/logoDark.png";
import styles from "./landingPage.module.scss";

export const LandingPage = () => {
  return (
    <div className={styles.wrapper}>
      <div className={styles.logo}>
        <img src={logoDark} alt="OHANA CIRCLE" />
      </div>
      <div className={styles.heading}>
        <div className={styles.logoWrapper}>
          <img src={logoDark} alt="OHANA CIRCLE" />
        </div>
        <div className={styles.headingWrapper}>
          <p>
            Ohana Circle <br />
            is coming soon
            <div className={styles.buttons}>
              <button>Mehr erfahren</button>
              <a
                title="Instagram"
                rel="noreferrer"
                target="_blank"
                href="https://www.instagram.com/ohanacircle_ev/"
              >
                <img src={instagram} />
              </a>
              <a
                title="LinkedIn"
                rel="noreferrer"
                target="_blank"
                href="https://linkedin.com"
              >
                <img src={linkedIn} />
              </a>
            </div>
          </p>
        </div>
      </div>
    </div>
  );
};
