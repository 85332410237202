import React from "react";
import styles from "./landingPage.module.scss";

export const ProvisionalFooter = () => {
  return (
    <div className={styles.footer}>
      <a href="/dsgvo">Datenschutzerklärung</a>
      <a href="impressum">Impressum</a>
    </div>
  );
};
