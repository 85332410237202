import React from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { DSGVO } from "./pages/dsgvo/dsgvo";
import { Impressum } from "./pages/impressum/impressum";
import { LandingPage } from "./pages/landingPage/landingPage";
import { ProvisionalFooter } from "./pages/landingPage/provisionalFooter";

export const App = () => {
  return (
    <Router>
      <Switch>
        <Route path="/impressum">
          <Impressum />
        </Route>
        <Route path="/dsgvo">
          <DSGVO />
        </Route>
        <Route exact path="/">
          <LandingPage />
          <ProvisionalFooter />
        </Route>
      </Switch>
    </Router>
  );
};
