import React from "react";
import styles from "./impressum.module.scss";

export const Impressum = () => {
  return (
    <div className={styles.wrapper}>
      <h1>Impressum</h1>
      <p>
        Ohana Circle (Verein in Gründung)
        <br />
        c/o Dr. Mandy Schönherr
        <br />
        Bertinistraße 11
        <br />
        <br />
        14469 Potsdam
        <br />
        <br />
        Telefon: +49 160 93915919
        <br />
        E-Mail: info@ohanacircle.de
        <br />
        <br />
        Verantwortlich für die journalistisch-redaktionellen Inhalte:
        <br />
        Kristina Gottschling, Dr. Mandy Schönherr
        <br />
        <br />
        Inhaltspflege und Webdesign:
        <br />
        Kristina Gottschling <br />
        <br />
        Grafiken: <br />
        Peerlings Design https://peerlings.wordpress.com/ <br />
        <br />
        <br />
        <br />
        <span className={styles.smaller}>
          1. Urheberrechtliche Hinweise:
          <br />
          <br />
          Die Inhalte dieser Website werden mit größtmöglicher Sorgfalt
          erstellt. Der Anbieter übernimmt jedoch keine Gewähr für die
          Richtigkeit, Vollständigkeit und Aktualität der bereitgestellten
          Inhalte. Die Nutzung der Inhalte der Website erfolgt auf eigene Gefahr
          des Nutzers. Namentlich gekennzeichnete Beiträge geben die Meinung des
          jeweiligen Autors und nicht immer die Meinung des Anbieters wieder.
          Mit der reinen Nutzung der Website des Anbieters kommt keinerlei
          Vertragsverhältnis zwischen dem Nutzer und dem Anbieter zustande.
          <br />
          <br />
          <br />
          2. Externe Links
          <br />
          <br />
          Diese Website enthält Verknüpfungen zu Websites Dritter (&quot;externe
          Links&quot;). Diese Websites unterliegen der Haftung der jeweiligen
          Betreiber. Der Anbieter hat bei der erstmaligen Verknüpfung der
          externen Links die fremden Inhalte daraufhin überprüft, ob etwaige
          Rechtsverstöße bestehen. Zu dem Zeitpunkt waren keine Rechtsverstöße
          ersichtlich. Der Anbieter hat keinerlei Einfluss auf die aktuelle und
          zukünftige Gestaltung und auf die Inhalte der verknüpften Seiten. Das
          Setzen von externen Links bedeutet nicht, dass sich der Anbieter die
          hinter dem Verweis oder Link liegenden Inhalte zu Eigen macht. Eine
          ständige Kontrolle der externen Links ist für den Anbieter ohne
          konkrete Hinweise auf Rechtsverstöße nicht zumutbar. Bei Kenntnis von
          Rechtsverstößen werden jedoch derartige externe Links unverzüglich
          gelöscht.
          <br />
          <br />
          <br />
          3. Urheber- und Leistungsschutzrechte
          <br />
          <br />
          Die auf dieser Website veröffentlichten Inhalte unterliegen dem
          deutschen Urheber- und Leistungsschutzrecht. Jede vom deutschen
          Urheber- und Leistungsschutzrecht nicht zugelassene Verwertung bedarf
          der vorherigen schriftlichen Zustimmung des Anbieters oder jeweiligen
          Rechteinhabers. Dies gilt insbesondere für Vervielfältigung,
          Bearbeitung, Übersetzung, Einspeicherung, Verarbeitung bzw. Wiedergabe
          von Inhalten in Datenbanken oder anderen elektronischen Medien und
          Systemen. Inhalte und Rechte Dritter sind dabei als solche
          gekennzeichnet. Die unerlaubte Vervielfältigung oder Weitergabe
          einzelner Inhalte oder kompletter Seiten ist nicht gestattet und
          strafbar. Lediglich die Herstellung von Kopien und Downloads für den
          persönlichen, privaten und nicht kommerziellen Gebrauch ist erlaubt.
          Die Darstellung dieser Website in fremden Frames ist nur mit
          schriftlicher Erlaubnis zulässig.
        </span>
      </p>
    </div>
  );
};
